@import '/../frontend/src//assets/scss/abstracts/variables';

.filter {
  border-bottom: 4px solid transparent;
  background-color: $items-color;
  padding: 16px 18px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.filter-title {
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-clear-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 18px;

  &:focus {
    outline: none;
  }
}

.filter-clear-icon.icon {
  width: 14px;
  height: 14px;
}

.filter-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 9px;
}

.filter-btn {
  &:enabled:hover {
    cursor: pointer;
    border-color: $theme-color;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.filter-slider-container {
  position: relative;
}

.filter-slider {
  width: 100%;
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background-color: $main-color;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background-color: $theme-color;
    border-radius: 50%;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background-color: $theme-color;
    cursor: pointer;
  }
}

.filter-slider-range {
  background-color: $theme-color;
  height: 2px;
  width: 80px;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 2;
}

.filter-tooltip {
  display: none;
  white-space: nowrap;
  position: absolute;
  top: -20px;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  background-color: $theme-color;
  transform: translate(-50%, 0);
  padding: 3px 7px;
  border-radius: $border-radius;

  .filter-slider-container:hover & {
    display: block;
  }

  &:focus {
    display: block;
  }
}

.filter-slider:focus + span {
  display: block;
}
