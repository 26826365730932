.snapshot-dropdown {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  width: fit-content;
  padding: 15px 26px;
  background-color: $items-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 2;
  display: none;

  &.isActive {
    display: block;
  }
}

.snapshot-dropdown-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 26px 16px 0;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  white-space: nowrap;
  cursor: pointer;

  &.isDisabled {
    cursor: default;
    opacity: 0.5;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $main-color;
  }
}

.snapshot-dropdown-icon.icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.snapshot-dropdown-arrow.icon {
  width: 11px;
  min-width: 11px;
  height: 11px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
