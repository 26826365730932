@import '/src/assets/scss/abstracts/variables';

.dashboard {
  position: fixed;
  top: 116px;
  right: 101px;
  z-index: 2;
  min-width: 378px;
  display: block;

  &.isOpen {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  &.isClosed {
    -webkit-animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.dashboard-body {
  background-color: $items-color;
  padding: 22px 37px 12px 37px;
  border-radius: $border-radius;
}

.dashboard-body-title {
  font-size: 18px;
  font-weight: 300;
  color: #fefefe;
  display: block;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
}

.dashboard-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 21px;
}

.dashboard-progress-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-progress-annotation {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fefefe;
  font-size: 18px;
  font-weight: 300;
}

.dashboard-arrow-icon.icon {
  position: absolute;
  width: 30px;
  height: 54px;
  background-size: 10px;
  transform: rotate(0deg);
  top: 8px;
  left: 8px;
  cursor: pointer;
}

.dashboard-road {
  margin-top: 5px;
  padding: 20px 25px 30px 25px;
}

.dashboard-line {
  gap: 0 25px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 19px;
    left: 0;
    background-color: $primary-color;
    opacity: 0.1;
  }
}

.dashboard-line-size {
  font-size: 30px;
  font-weight: normal;
  color: $primary-color;
  margin-bottom: 2px;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .dashboard-progress-circle & {
    margin-top: 8px;
  }
}

.dashboard-line-distance {
  font-size: 12px;
  font-weight: lighter;
  color: #fff;
  display: block;
  height: 100%;
  margin-left: 5px;
  margin-bottom: 4px;
}

.dashboard-line-title {
  font-size: 12px;
  font-weight: lighter;
  color: $primary-color;
  text-align: center;
  margin-bottom: 5px;
}

.dashboard-progress-bars {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 80%;
  margin: 0 auto;
}

.dashboard-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.dashboard-vertical-bar {
  width: 12px;
  display: block;

  .dashboard-vertical.bordered & {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}

.dashboard-vertical-text {
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 0);
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
