@import '../../../../../assets/scss/abstracts/variables';

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 100vh;
  width: 50%;
  z-index: 12;
  background-color: $items-color;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px;

  @media (min-width: $breakpoint-xxl) {
    width: 31%;
  }
}

.sidebar-container {
  padding: 0 20px;
}

.sidebar-header {
  padding: 46px 20px 0 20px;
}

.sidebar-title {
  display: block;
  font-size: 24px;
  font-weight: 300;
  color: $primary-color;
  text-transform: capitalize;
  text-align: center;
}

.sidebar-subtitle {
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: $primary-color;
  margin-top: 5px;
  margin-bottom: 22px;
  text-align: center;
}

.sidebar-cards {
  overflow-y: auto;
  padding: 0 20px;
  height: 100%;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
    background-color: #000;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7500;
    border-radius: 0;
  }
}

.sidebar-btn {
  display: block;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  margin-left: 15px;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

.sidebar-icon.icon {
  width: 100%;
  height: 100%;
}

.sidebar-close {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 17px;
  height: 17px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.sidebar-tab-list {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #eeeeee90;
}

.sidebar-tab {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  text-transform: capitalize;
  padding: 8px 15px;
  position: relative;
  cursor: pointer;

  &[area-disabled='true'] {
    cursor: default;
    opacity: 0.5;
  }

  &.active {
    font-weight: bold;
    color: $theme-color;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: $theme-color;
    }
  }

  &:hover {
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: $theme-color;
    }
  }
}

.sidebar-tab-icon.icon {
  width: 11px;
  height: 11px;
  margin-right: 4px;
}

.sidebar-card {
  display: grid;
  grid-template-columns: auto 1fr auto;
  box-shadow: $box-shadow;
  width: 100%;
  background-color: $main-color;
  border-radius: $border-radius;
  padding: 5px 15px 5px 5px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.sidebar-card-title {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: $primary-color;
}

.sidebar-card-subtitle {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
}

.sidebar-card-container {
  position: relative;
  height: 102px;
  min-width: 172px;
  width: 10vw;
}

.sidebar-card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: $border-radius;
}

.sidebar-card-info {
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  margin-left: 22px;
}

.sidebar-card-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.sidebar-card-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.sidebar-card-icon.icon {
  width: 100%;
}

.sidebar-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.sidebar-sort-count {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
}

.sidebar-cards-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  margin-top: 16px;
  margin-bottom: 5px;
}

.sidebar-sort-container {
  display: flex;
  align-items: center;
}

.sidebar-sort-title {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-right: 14px;
}

.sidebar-sort-dropdown {
  padding: 4px 11px;
  border: 1px solid #6c6c6c;
  border-radius: 5px;

  .dropdown {
    width: auto;
  }

  .dropdown-text {
    font-size: 12px;
  }

  .dropdown-item {
    font-size: 12px;
    padding: 12px;
  }
}

.sidebar-form {
  margin-top: 8px;
  margin-bottom: 12px;
}

.sidebar-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 9px;
}

.sidebar-input {
  display: block;
  width: 100%;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  padding: 0 15px;
  height: 46px;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  background-color: transparent;
}

.sidebar-preview {
  display: block;
  width: 100%;
  max-height: 270px;
}

.sidebar-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  margin-top: 25px;

  &:first-of-type {
    margin-top: 0;
  }
}

.sidebar-select-title {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
}

.sidebar-select-btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  padding: 2px;
  height: 33px;
}

.sidebar-select-btn {
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  padding: 2px 16px;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &.isActive {
    background-color: $theme-color;
  }

  &:focus {
    outline: none;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.sidebar-checkbox-line {
  display: flex;
  align-items: center;
  background-color: #313131;
  padding: 12px 19px;
  border-radius: $border-radius;
}

.sidebar-areas {
  display: block;
  padding: 14px 10px 14px 20px;
  background-color: #313131;
  border-radius: $border-radius;
}

.sidebar-area-container {
  display: block;
  padding-right: 14px;
  max-height: 185px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
    background-color: #000;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 0;
  }
}

.sidebar-area {
  display: flex;
  align-items: center;
  padding: 14px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $main-color;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.sidebar-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 50px;
  min-height: 50px;
  border: 1px solid $primary-color;
  margin-top: 30px;
  margin-left: -20px;
  margin-right: -20px;
}

.sidebar-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &.main {
    background-color: $primary-color;
    color: $main-color;
  }

  &:hover:not(:disabled) {
    color: $theme-color;
  }
}
