@import '../../../../../assets/scss/abstracts/variables';

.list-view {
  position: fixed;
  width: 570px;
  top: 167px;
  right: 101px;
  z-index: 10;
  border-radius: $border-radius;
  overflow: hidden;

  &.isVisible {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  &.isHidden {
    -webkit-animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.list-head {
  background-color: $items-color;
  padding: 10px 20px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}

.list-head-breadcrumbs {
  display: flex;
  align-items: center;
}

.list-head-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 10px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 492.004 492.004' viewBox='0 0 492.004 492.004'%3E%3Cpath fill='%23fff' d='M382.678 226.804L163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z'/%3E%3C/svg%3E%0A");
    }
  }

  &:last-child {
    font-weight: bold;
  }
}

.list-refresh-icon.icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.list-toggler {
  display: flex;
  align-items: center;
  margin-left: auto;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  height: 33px;
  padding: 2px;
}

.list-toggler-btn {
  display: block;
  height: 100%;
  background-color: transparent;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  border: none;
  text-align: center;
  min-width: 88px;
  padding: 0 20px;
  cursor: pointer;

  &:hover:not(.active) {
    color: $theme-color;
  }

  &.active {
    background-color: $theme-color;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.list-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 96%;
}

.list-table-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
}

.list-table-input {
  position: relative;
  z-index: 2;
  opacity: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:checked ~ .list-table-checkbox {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 512 512' viewBox='0 0 512 512'%3E%3Cpath fill='%23ff7500' d='M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.list-table-checkbox {
  display: block;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  width: 18px;
  height: 18px;
  background-color: $items-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

%column {
  position: relative;
  font-size: 12px;
  color: $primary-color;
  border-bottom: 1px solid $main-color;
  text-align: left;
  line-height: 1;

  &:not(:first-child) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.list-table-container {
  background-color: $items-color;
  max-height: 424px;
  display: block;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
    background-color: $items-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7500;
    border-radius: 0;
  }
}

.list-tr .list-th {
  position: sticky;
  top: 0;
  background-color: $items-color;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.list-tr {
  cursor: pointer;

  &.isSelected,
  &:hover {
    background-color: #313131;
    border-radius: $border-radius;
  }
}

.list-th {
  @extend %column;
  font-weight: bold;
  padding-top: 9px;
  padding-bottom: 9px;
}

.list-th-sort {
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list-th-icon.icon {
  display: block;
  width: 9px;
  height: 9px;
  margin-left: 5px;

  &.isUp {
    transform: rotate(270deg);
  }
}

.list-td {
  @extend %column;
  font-weight: 300;
  padding-top: 8px;
  padding-bottom: 8px;

  &:last-child {
    padding: 0;
  }
}

.list-severity {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }

  &.high {
    color: #da4453;

    &::before {
      background-color: #da4453;
    }
  }

  &.med {
    color: #ff7500;

    &::before {
      background-color: #ff7500;
    }
  }

  &.low {
    color: #f6bb42;

    &::before {
      background-color: #f6bb42;
    }
  }
}

.list-td-icon.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.list-table-pad {
  padding: 7px 22px 15px 20px;
}

.list-main {
  background-color: $items-color;
}

.list-alert {
  background-color: $main-color;
  padding: 17px 20px;
  display: flex;
  align-items: center;
}

.list-alert-title {
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: $primary-color;
  margin-right: 14px;
}

.list-alert-subtitle {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-right: auto;

  b {
    font-weight: bold;
  }
}

.list-alert-btn {
  background-color: transparent;
  border: none;
  width: 13px;
  height: 13px;
  display: block;
  margin-left: 19px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.list-alert-icon {
  width: 100%;
  height: 100%;
  transform: rotate(270deg);

  .list-alert-btn.isActive & {
    transform: rotate(90deg);
  }
}

.list-info {
  padding: 12px 65px 12px 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.list-info-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  color: $primary-color;
}

.list-info-subtitle {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;

  &.preview {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

.list-info-btn {
  position: absolute;
  right: 17px;
  top: 18px;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.list-info-icon {
  height: 100%;
  width: 100%;
}

.list-col {
  width: 37%;
}

.list-images {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 10px;
}

.list-images-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-images-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
}

.list-images-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: transparent;
  border: 1px solid $primary-color;
  border-radius: $border-radius;
  height: 29px;
  padding: 8px 35px;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $theme-color;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.list-img {
  display: block;
  width: 100%;
  height: 75px;
  object-fit: cover;
  object-position: center;
  border-radius: $border-radius;
  margin-bottom: 3px;
}

.list-img-title {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
}

.list-col-3 {
  width: 20%;
  flex: 0 0 20%;
}

.list-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  margin-top: 15px;

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}
