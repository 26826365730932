@import '../../../../../assets/scss/abstracts/variables';

.stilized {
  position: relative;
  display: flex;
  height: 33px;
  border-radius: $border-radius;
  border: 1px solid #6c6c6c;
  background-color: $main-color;
  cursor: pointer;
  padding: 0 1px;
}

.stilized-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: calc(100% - 2px);
  margin-top: 1px;
  background-color: transparent;
  border: none;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: $theme-color;
  }

  .stilized-btn:not(:last-child) & {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      height: 100%;
      width: 1px;
      border-right: 1px solid #6c6c6c;
    }
  }

  .stilized-btn:first-child & {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .stilized-btn:last-child & {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}

.stilized-icon.icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 21px;
  height: 100%;
}
