.coordinates-checker {
  color: white;
  z-index: 999;
  text-align: center;
}

.coordinates-checker label {
  display: block;
}

.coordinates-checker form {
  display: block;
  background-color: black;
  padding: 10px;
}
