@import '../../../../../assets/scss/abstracts/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  background-color: $main-color;
  padding: 0 20px;

  @media (min-width: $breakpoint-xxl) {
    padding: 0 2vw;
  }
}

.header-list-item {
  cursor: pointer;
  border-left: 1px solid $border-color;
  padding: 0 6px;

  @media (min-width: $breakpoint-lg) {
    padding: 0 12px;
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 0 16px;
  }

  &:last-child {
    border-right: 1px solid $border-color;
  }

  &.active {
    border-bottom: 2px solid $theme-color;
  }

  &.disabled {
    cursor: default;
  }
}

.header-list-icon.icon {
  width: 17px;
  height: 20px;
  object-fit: contain;
  display: block;
  margin-right: 8px;
}

.header-list-text {
  display: block;
  font-size: 14px;
  color: $secondary-color;

  @media (min-width: $breakpoint-xxl) {
    font-size: 16px;
  }

  .header-list-item:hover:not(.disabled) &,
  .header-list-item.active & {
    color: $theme-color;
  }
}

.header-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
}

.header-btn-icon.icon {
  width: 21px;
  display: block;
  margin: 10px;
}

.header-user {
  margin-left: 10px;

  @media (min-width: $breakpoint-xl) {
    margin-left: 22px;
  }
}

.header-user-icon.icon {
  width: 20px;
  margin-right: 8px;
  display: block;
}

.header-dropdown {
  padding: 4px 11px;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  width: 170px;

  &:first-child {
    margin-left: 10px;
  }

  @media (min-width: $breakpoint-xxl) {
    width: 190px;
  }

  .dropdown-btn {
    width: 100%;
    justify-content: space-between;
  }
}

.header-logout.icon {
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.header-logo-tactile {
  max-height: 28px;
  margin-right: 15px;

  @media (min-width: $breakpoint-xxl) {
    margin-right: 61px;
  }
}

.header-logo-img {
  max-height: 37px;
  margin-left: 15px;
  min-width: 65px;
  object-fit: contain;

  @media (min-width: $breakpoint-xxl) {
    margin-left: 61px;
  }
}

.header-center {
  margin: 0 auto;
  gap: 13px;
}

.header-vehicles.header-dropdown {
  .dropdown-group {
    width: 100%;
    min-width: unset;
    max-height: unset;
    overflow-y: visible;
    background-color: transparent;
  }
}

.header-vehicles-head {
  padding: 0 11px;
  background-color: $items-color;

  .dropdown-item {
    font-size: 12px;
    font-weight: 300;
    padding: 11px 0;
    border-bottom: 1px solid #313131;

    &:first-child {
      color: $theme-color;
      font-weight: bold;
    }
  }
}

.header-vehicles-list {
  background-color: $main-color;
  max-height: 230px;
  overflow-y: auto;
  padding: 9px 0;
  border-radius: $border-radius;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
    background-color: $items-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7500;
    border-radius: 0;
  }

  .dropdown-item {
    background-color: $main-color;
    font-size: 12px;
    font-weight: 300;
    padding: 5px 11px;
  }
}

.header-vehicles-container {
  padding: 11px;
  background-color: $items-color;
}

.header-vehicles-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  padding: 10px;
  color: $primary-color;
  background-color: $items-color;
  cursor: pointer;
  margin-top: 3px;
}

.header-vehicles-title {
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-bottom: 7px;
  display: block;
}

.header-vehicles-icon.icon {
  width: 8px;
  height: 8px;
  margin: 0 6px;

  &.active {
    transform: rotate(270deg);
  }
}
