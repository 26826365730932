.controls-btn-container {
  position: fixed;
  z-index: 5;

  &.global-controls {
    top: 116px;
    left: 38px;
  }

  &.map-controls {
    right: 38px;
    bottom: 71px;
  }

  &.statistics-controls {
    top: 116px;
    right: 38px;
  }

  &.dash-controls {
    top: 166px;
    right: 38px;
  }
}

.controls-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 50%;
  background-color: $primary-color;
  border: none;
  box-shadow: $box-shadow;
  position: relative;

  &.cursor.active {
    &::after {
      content: none;
    }
  }

  &.controls-clipboard {
    position: relative;

    &.active {
      &::after {
        left: -3px;
        transform: translate(0, -50%) rotate(135deg);
      }
    }
  }

  &.active {
    background-color: $theme-color;

    &::after {
      content: '';
      border-style: solid;
      border-width: 0 0 12px 12px;
      height: 0;
      width: 0;
      border-color: transparent;
      border-bottom-color: $theme-color;
      display: inline-block;
      position: absolute;
      right: -3px;
      top: 50%;
      transform: translate(0, -50%) rotate(-45deg);

      .controls-btn-container.statistics-controls & {
        left: -3px;
        transform: translate(0, -50%) rotate(135deg);
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .controls-btn-container.map-controls & {
    margin-top: 15px;
  }

  .statistics-controls & {
    margin-bottom: 9px;
  }
}

.controls-icon {
  display: block;
  width: 20px;
  height: 19px;

  &.icon-target {
    width: 25px;
    height: 25px;
  }
}

.controls-solid {
  margin-top: 15px;
  border-radius: 21px;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.controls-solid-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 39px;
  cursor: pointer;
  background-color: $primary-color;
  border: none;

  &:first-child {
    border-bottom: 1px solid $main-color;
  }
}

.controls-group {
  position: relative;
  margin-bottom: 8px;
}

.control-title {
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.7px;
  color: $primary-color;
  margin-bottom: 20px;
}

.controls-container {
  position: absolute;
  top: 0;
  left: 62px;
  min-width: 302px;
  z-index: 2;
  border-radius: $border-radius;
  overflow: hidden;

  &.isVisible {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  &.isHidden {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.control-func-btn {
  background-color: $primary-color;
  color: $main-color;
  border: none;
  min-height: 20px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;

  &:not(:disabled):hover {
    background-color: darken($color: #fff, $amount: 10%);
  }

  &:disabled {
    cursor: default;
    background-color: darken($color: #fff, $amount: 20%);
  }
}

.controls-header {
  background-color: $items-color;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  color: $primary-color;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.controls-header-icon.icon {
  width: 11px;
  height: 11px;
  cursor: pointer;
}

.controls-footer {
  display: flex;
  align-items: center;
}

.controls-footer-btn {
  background-color: $primary-color;
  font-size: 12px;
  font-weight: bold;
  color: $main-color;
  border: none;
  padding: 5px 10px;
  flex: 1px;

  &:enabled:hover {
    cursor: pointer;
    color: $theme-color;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

.controls-body {
  .controls-container.isClosed & {
    display: none;
  }
}

.controls-title {
  .controls-container.isClosed & {
    margin: 0;
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
