//TODO: Change later
@import '../../../../../../assets/scss/abstracts/variables';

.search .controls-container {
  position: absolute;
  left: 62px;
  top: 0;
}

.search-container {
  background-color: $main-color;
  min-width: 302px;
  border-radius: 5px;
  overflow: hidden;

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    width: 100%;
    border: none;
    box-shadow: none;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
  }

  .mapboxgl-ctrl-geocoder--input {
    background-color: $main-color;
    width: 100%;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    padding: 16px 40px;
    height: auto;

    &:focus {
      outline: none;
    }
  }

  .mapboxgl-ctrl-geocoder--button {
    background-color: $main-color;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    margin: 0;
  }

  .suggestions {
    position: relative;
    min-width: 302px;
    width: initial;
    border-radius: 0;
    background-color: $main-color;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 9px;
    gap: 9px;
    padding: 16px 18px;

    &.is-opened {
      display: grid !important;
    }

    &.no-results {
      display: block;
    }
  }

  .mapboxgl-ctrl-geocoder--suggestion-address {
    white-space: unset;
  }

  .suggestions > li > a {
    color: $primary-color;
    padding: 0;
    overflow: hidden;
    height: 100%;
  }

  .suggestions > .active > a,
  .suggestions > li > a:hover {
    color: $theme-color;
    background-color: $main-color;
    border-color: $theme-color;
  }

  .mapboxgl-ctrl-geocoder--suggestion {
    border: 1px solid;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 300;
    padding: 5px 10px;
    height: 100%;

    &:enabled:hover {
      cursor: pointer;
      border-color: $theme-color;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
