@import '../../../../../assets/scss/abstracts/variables';

.polygon {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: $items-color;
  border-radius: $border-radius;
  padding: 52px 58px;
  min-width: 40%;
}

.polygon-title {
  display: block;
  font-size: 24px;
  font-weight: 300;
  color: $primary-color;
  margin-top: 9px;
  margin-bottom: 21px;
  width: 100%;
  text-align: center;
}

.polygon-input {
  display: flex;
  align-items: center;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  padding: 0 22px;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  background-color: $items-color;
  height: 39px;
  width: 100%;

  &::placeholder {
    color: #5e5e5e;
  }

  &.isIncorrect {
    border-color: $danger-color;
  }
}

.polygon-label {
  position: relative;
  display: block;
}

.polygon-error {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  font-size: 12px;
  color: $danger-color;
}

.polygon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  background-color: transparent;
  border: 1px solid $primary-color;
  border-radius: $border-radius;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  padding: 0 10px;
  cursor: pointer;
  white-space: nowrap;

  &.fill {
    background-color: $primary-color;
    color: $items-color;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    color: $theme-color;
  }
}

.polygon-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 26px;
}
